import Page from './components/Page';

function App() {
  return (
    <div className="App">
      <Page/>
    </div>
  );
}

export default App;
